import axios from "axios";

const instance = axios.create({
  alg: "HS256",
  typ: "jwt",
  withCredentials: true,
  secure: true,
  baseURL: "/qrobotix/api/v1",
});

export default instance;
