import React from "react";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-[#122cdb] w-full text-white text-center text-base py-8 px-4 mt-[40px]"
      style={{
        fontFamily: "Arial, sans-serif",
        boxSizing: "border-box",
      }}
    >
      <p className="mb-8">
        Copyright © 2024 QROBOTIX CLUB - All Rights Reserved.
      </p>
      <p
        className="cursor-pointer"
        onClick={() => {
          navigate("/signup");
        }}
      >
        FEE AND REGISTRATION
      </p>
    </div>
  );
};

export default Footer;
