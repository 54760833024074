import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import CenteredText from "../Components/Heading";
import Events from "../Components/Events";
import codevaourMumbai from "../asset/EventsAndProgram/codeavourMumbai.webp";
import codeavourBengaluru from "../asset/EventsAndProgram/codeavourBengaluru.webp";

const EventsAndProgram = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(80);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    if (window.innerWidth <= 1024) {
      setWidth(100);
    } else if (window.innerWidth <= 1405) {
      setWidth(90);
    } else if (window.innerWidth <= 1850) {
      setWidth(80);
    }
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="flex flex-col min-h-screen justify-between w-full">
      <div className="mb-[20px] ml-[10px] mr-[10px] mt-[calc(40px+10em)] max-[1024px]:mt-[calc(40px+6em)] flex flex-col items-center justify-center">
        <div
          className={`flex flex-col gap-1 items-center justify-center ${`w-[${width}vw]`}`}
        >
          <h1 className="text-3xl font-bold uppercase text-center text-[#122cdb] ">
            CODEAVOUR 5.0 - INNOVATION FEST FOR NEXT GEN
          </h1>
          <div
            className={`flex ${
              isMobile ? "flex-col gap-[40px]" : "flex-row gap-[20px]"
            } mb-[40px] items-center  w-[100%] justify-top `}
          >
            <div
              className={`video-container mt-[40px]  ${
                isMobile ? "max-w-[100%]" : "w-[100%] md:w-[70%]"
              }`}
            >
              <Events
                heading="CODEAVOUR 5.0 BENGALURU REGIONALS, 31 MAR 24"
                src={codeavourBengaluru}
                onClick={() => {
                  window.open("https://codeavour.org/register/", "_blank");
                }}
              />
            </div>
            <div
              className={`video-container mt-[40px]  ${
                isMobile ? "max-w-[100%]" : "w-[100%] md:w-[70%]"
              }`}
            >
              <Events
                heading="CODEAVOUR 5.0 BENGALURU REGIONALS, 31 MAR 24"
                src={codevaourMumbai}
                onClick={() => {
                  window.open("https://codeavour.org/register/", "_blank");
                }}
              />
            </div>
          </div>
          <CenteredText text={"TECHNOXIAN WORLDCUP 2023"} />
          <div
            className={`video-container mt-[40px]  ${
              isMobile ? "w-[90%] h-[35vh]" : " w-[100%] h-[55vh]"
            }`}
          >
            <iframe
              title="YouTube Video"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/VrAJ4FYEZEc"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div
            className={`flex ${
              isMobile ? "flex-col" : "flex-row"
            } gap-5 w-[100%] justify-center items-center mb-[40px]`}
          >
            <div
              className={`video-container mt-[40px]  ${
                isMobile ? "w-[90%] h-[30vh]" : "w-[50%] h-[25vh]"
              } ${isMobile ? "h-[35vh]" : "h-[30vh]"}`}
            >
              <iframe
                title="YouTube Video"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/DEOkGqcBmq8"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div
              className={`video-container mt-[40px]  ${
                isMobile ? "w-[90%]" : "w-[50%]"
              } ${isMobile ? "h-[35vh]" : "h-[30vh]"}`}
            >
              <iframe
                title="YouTube Video"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/WWo2QOfQLoQ"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EventsAndProgram;
