import React, { useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoginBackground from "../asset/AuthenticationImages/LoginBackground.svg";
import Qrobotix from "../asset/logo/qrobotix.png";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { message } from "antd";
import api from "../BaseURL/api";

const Signup = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handlePhoneNumberChange = (value) => {
    setErrors((prev) => ({ ...prev, phoneNumber: false }));
    setErrorsMessage((prev) => ({
      ...prev,
      phoneNumber: "Phone Number is required",
    }));
    setPhoneNumber(value);
  };
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });

  const [errorsMessage, setErrorsMessage] = useState({
    firstName: "First Name is required",
    lastName: "Last Name is required",
    email: "Email is required",
    phoneNumber: "Phone Number is required",
  });

  const validateForm = () => {
    let valid = true;
    const errorsCopy = { ...errors };
    const errorsMessageCopy = { ...errorsMessage };

    if (!firstName.trim()) {
      errorsCopy.firstName = true;
      valid = false;
    } else {
      errorsCopy.firstName = false;
    }

    if (!lastName.trim()) {
      errorsCopy.lastName = true;
      valid = false;
    } else {
      errorsCopy.lastName = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === "") {
      errorsCopy.email = true;
    } else {
      if (!emailRegex.test(email.trim())) {
        errorsCopy.email = true;
        errorsMessageCopy.email = "Please enter a valid email";
        valid = false;
      } else {
        errorsCopy.email = false;
      }
    }

    if (!matchIsValidTel(phoneNumber.trim())) {
      errorsCopy.phoneNumber = true;
      errorsMessageCopy.phoneNumber = "Please enter a valid phone number";
      valid = false;
    } else {
      errorsCopy.phoneNumber = false;
    }

    setErrors(errorsCopy);
    setErrorsMessage(errorsMessageCopy);
    return valid;
  };

  const handleSignUp = async () => {
    if (!validateForm()) {
      return; // If form is not valid, do not proceed
    }

    const signUpData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
    };

    try {
      const response = await api.post("/signup", signUpData);
      console.log(response);
      if (response.status === 201) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          message.destroy();
          message.success("Sign Up Successful");
          setEmail("");
          setFirstName("");
          setLastName("");
          setPhoneNumber("");
        } else {
          const responseText = await response.text();
          message.destroy();
          message.error(responseText);
        }
      } else {
        const errorResponse = await response.json();
        const errorMessage =
          errorResponse.message || "An unexpected error occurred";
        message.destroy();
        message.error(errorMessage);
      }
      // message.error('Something Went Wrong. Please Try Again Later');
    } catch (error) {
      message.destroy();
      message.error("An error occurred during sign-up. Please try again");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${LoginBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center",
        padding: isSmallScreen ? "20px" : "0",
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={5} style={{ maxWidth: "550px" }}>
        <Paper
          elevation={3}
          style={{
            padding: "48px 24px",
            borderRadius: "4px",
            backgroundColor: "white",
          }}
        >
          <img
            src={Qrobotix}
            alt="Company Logo"
            style={{
              width: "142px",
              height: "90px",
              margin: "auto",
            }}
          />
          <div
            style={{
              width: "100%",
              textAlign: "center",
              color: "#242C30",
              fontSize: 24,
              fontFamily: "Archivo",
              fontWeight: "700",
              marginBottom: "32px",
              marginTop: "8px",
              lineHeight: 1,
              wordWrap: "break-word",
            }}
          >
            Create Account
          </div>
          <p className="text-sm font-normal font-sans text-center mb-[16px] text-[#5E5E5E]">
            By creating an account, you may receive newsletters or promotions.
          </p>
          <div style={{ width: "100%", display: "flex", gap: "16px" }}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="First Name *"
                variant="filled"
                value={firstName}
                InputLabelProps={{
                  style: { color: "#677379" },
                }}
                FormHelperTextProps={{
                  style: { color: "#677379", margin: 0, padding: 0 },
                }}
                InputProps={{
                  style: { color: "#677379" },
                }}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setErrors((prev) => ({
                    ...prev,
                    firstName: false,
                  }));
                }}
                error={errors.firstName}
                helperText={errors.firstName ? errorsMessage.firstName : ""}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Last Name *"
                variant="filled"
                InputLabelProps={{
                  style: { color: "#677379" },
                }}
                InputProps={{
                  style: { color: "#677379" },
                }}
                FormHelperTextProps={{
                  style: { color: "#677379", margin: 0, padding: 0 },
                }}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setErrors((prev) => ({
                    ...prev,
                    lastName: false,
                  }));
                }}
                error={errors.lastName}
                helperText={errors.lastName ? errorsMessage.lastName : ""}
              />
            </FormControl>
          </div>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Email *"
              variant="filled"
              type="email"
              InputLabelProps={{
                style: { color: "#677379" },
              }}
              InputProps={{
                style: { color: "#677379" },
              }}
              FormHelperTextProps={{
                style: { color: "#677379", margin: 0, padding: 0 },
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prev) => ({
                  ...prev,
                  email: false,
                }));
                setErrorsMessage((prev) => ({
                  ...prev,
                  email: "Email is required",
                }));
              }}
              error={errors.email}
              helperText={errors.email ? errorsMessage.email : ""}
            />
          </FormControl>
          {/* <FormControl fullWidth margin="normal">
            <TextField
              id="phone"
              name="phone"
              label="Phone Number *"
              variant="filled"
              InputLabelProps={{
                style: { color: "#677379" },
              }}
              FormHelperTextProps={{
                style: { color: "#677379", margin: 0, padding: 0 },
              }}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              error={errors.phoneNumber}
              helperText={
                errors.phoneNumber ? "Please enter a valid phone number" : ""
              }
              InputProps={{
                style: { color: "#677379" },
              }}
            />
          </FormControl> */}
          <FormControl fullWidth margin="normal">
            <MuiTelInput
              id="phone"
              name="phone"
              label="Phone Number *"
              variant="filled"
              defaultCountry={"IN"}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={errors.phoneNumber}
              InputLabelProps={{
                style: { color: "#677379" },
              }}
              helperText={errors.phoneNumber ? errorsMessage.phoneNumber : ""}
              FormHelperTextProps={{
                style: { color: "#677379", margin: 0, padding: 0 },
              }}
              InputProps={{
                style: { color: "#677379" },
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSignUp();
                }
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    disableScrollLock: true,
                    maxHeight: 200,
                    overflow: "auto",
                    background: "#F2F2F3",
                  },
                },
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSignUp}
            style={{
              fontWeight: "bold",
              marginTop: "64px",
              padding: "16px",
              backgroundColor: "#122cdb",
              color: "#F2F2F3",
              textTransform: "none",
            }}
          >
            Sign Up
          </Button>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "12px",
              gap: 4,
              display: "flex",
            }}
          >
            <div
              style={{
                color: "#242C30",
                fontSize: 14,
                fontWeight: "400",
                lineHeight: 1,
                wordWrap: "break-word",
              }}
            >
              Have a Qrobotix account?
            </div>
            <div
              style={{
                color: "#122cdb",
                fontSize: 14,
                fontFamily: "Inter",
                fontWeight: "500",
                lineHeight: 1,
                wordWrap: "break-word",
                cursor: "pointer", // Ensure the cursor changes on hover
              }}
              onClick={() => navigate("/login")}
            >
              Log in
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Signup;
