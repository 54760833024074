import React, { useRef } from "react";
import Heading from "../Components/Heading";
import SubscribtionDetail from "../Components/SubscribtionDetail";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Partners AndS ponsorers
import AmritaVishwa from "../asset/partners and sponsorers/amrita-vishwa.webp";
import ChildrenAcademy from "../asset/partners and sponsorers/childrenacademy.webp";
import Codeavour from "../asset/partners and sponsorers/codeavour.webp";
import NewHorizon from "../asset/partners and sponsorers/new horizon.webp";
import Qugates from "../asset/partners and sponsorers/qugates_logo.webp";
import Stempedia from "../asset/partners and sponsorers/stempedia.webp";
import Footer from "../Components/Footer";

const images = [
  { img: AmritaVishwa, alt: "Amrita Vishwa" },
  { img: ChildrenAcademy, alt: "Children Academy" },
  { img: Codeavour, alt: "Codeavour" },
  { img: Qugates, alt: "Qugates" },
  { img: NewHorizon, alt: "New Horizon" },
  { img: Stempedia, alt: "Stempedia" },
];

const IndividualAndGroup = () => {
  const settings = {
    infinite: true,
    speed: 10,
    slidesToShow: 3, // Number of slides to show initially on large screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for large devices
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Breakpoint for medium devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const marqueeRef = useRef(null);
  const handleMouseEnter = () => {
    if (marqueeRef.current) {
      marqueeRef.current.style.animationPlayState = "paused";
    }
  };

  const handleMouseLeave = () => {
    if (marqueeRef.current) {
      marqueeRef.current.style.animationPlayState = "running";
    }
  };
  const contents = [
    {
      heading: "EXPLORER",
      description:
        "This category is ideal for individuals who are new to robotics, drones, and AI. It offers access to introductory workshops, beginner-level training sessions, and educational resources to help members learn the basics and explore their interest in these fields.",
    },
    {
      heading: "ENTHUSIAST",
      description:
        "This category is ideal for individuals who are new to robotics, drones, and AI. It offers access to introductory workshops, beginner-level training sessions, and educational resources to help members learn the basics and explore their interest in these fields.",
    },
    {
      heading: "INNOVATOR",
      description:
        "The Innovator category is for members who have some experience or knowledge in robotics, drones, and AI. They can participate in advanced workshops, collaborate on innovative projects, and access intermediate-level resources to further enhance their skills.",
    },
    {
      heading: "INVENTOR",
      description:
        "The Professional category is designed for working professionals in the field of robotics, drones, AI, or related industries. This membership offers networking opportunities, industry-focused workshops, and access to advanced resources to support professional development and collaboration with like-minded individuals.",
    },
    {
      heading: "MENTOR",
      description:
        "The Mentor category is for experienced individuals who wish to contribute their expertise and guidance to club members. Mentors can provide mentorship, lead workshops, support project development, and share industry insights, helping members enhance their skills and achieve their goals.",
    },
    {
      heading: "LEADER",
      description:
        "The Leader is both a member and a stakeholder in the Organisation. They have a larger role to Lead , Mentor and take a lead role in shaping the vision and mission of the Club. They are part of key decision-making process and they have an accountability for delivery as well. The more they deliver the higher they grow and eventually they will be slotted for various positions with Remuneration in the Organisation. This is where all members should aspire to get to. ",
    },
  ];
  return (
    <div className="flex flex-col min-h-screen justify-between w-full">
      <div className="mt-[calc(40px+10em)] max-[1024px]:mt-[calc(40px+6em)] flex flex-col items-center">
        <div
          className="marquee-container overflow-hidden relative w-full h-10 "
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="marquee-content absolute inset-0 flex items-center">
            <div
              ref={marqueeRef}
              className="marquee-text text-[36px] text-[#122cdb] font-bold whitespace-nowrap overflow-hidden"
            >
              <span>
                SUBSCRIBE AND AVAIL SPECIAL BENEFITS WITH 50% DISCOUNTS & ENTRY
                TO WORLD ROBOTICS CHAMPIONSHIP
              </span>
            </div>
          </div>
        </div>
        <div className="pt-[56px]">
          <Heading text={"MEMBERSHIP CATEGORIES & SUBSCRIBTION DETAIL"} />
        </div>
        <div className="max-w-[1000px] pb-[50px]">
          {contents.map((content, index) => (
            <div key={index}>
              <SubscribtionDetail
                heading={content.heading}
                description={content.description}
              />
            </div>
          ))}
          <span className="font-bold text-[#5E5E5E]">Note: </span>
          <span className="text-[#5E5E5E]">
            Selection to the Leadership Category will be at the Sole Discretion
            of the Club through multiple level of Interviews and detailed
            Scrutiny of the CV{" "}
          </span>
        </div>
        <div>
          <h3 className="text-center text-[36px] font-bold text-[#122CDB]">
            OUR PARTNERS
          </h3>
          <div className="max-w-[40vw] mx-0 pt-[20px] ">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image.img} alt={image.alt} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndividualAndGroup;
