import React, { useEffect, useState } from "react";
import qrobotixForInstitutionalMsembership from "../asset/InstitutionalMembersip/qrobotix-for-institutional.webp";
import benifitsInstitutional from "../asset/InstitutionalMembersip/benefits.webp";
import whyConnect from "../asset/InstitutionalMembersip/whyConnect.webp";
import Footer from "../Components/Footer";
import CenteredText from "../Components/Heading";
import { Button } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";

const InstitutionalMembership = () => {
  const [isMobile, setIsMobile] = useState(false);
  //This variable can be used to set the size of the conten i.e image and text for various screen resolution.
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const openWhatsapp = () => {
    window.open("https://wa.me/919019733008", "_blank");
  };

  return (
    <div className="flex flex-col justify-between min-h-screen w-full">
      <div className="">
        <div
          className={`m-auto flex flex-col m-auto items-center justify-center mt-[calc(40px+10em)] max-[1024px]:mt-[calc(40px+6em)] ${
            isMobile ? "w-[90vw]" : "lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]"
          }`}
        >
          <div
            className={`m-auto flex flex-col m-auto items-center justify-center ${
              isMobile ? "w-[90vw]" : "lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]"
            }`}
          >
            <CenteredText text="QROBOTIX FOR INSTITUTIONS" />
            <div
              className={`flex ${
                isMobile ? "flex-col items-center" : "flex-row items-start"
              } gap-5 w-[100%] justify-center  mb-[40px]`}
            >
              {/* Content Section */}
              <div
                className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"}`}
              >
                <div
                  className={`mb-4 ${
                    isMobile
                      ? "w-full"
                      : "w-[100%] flex flex-col justify-centerx ms-[40px]"
                  }`}
                >
                  <div
                    className={`text-[#1B1B1B] mb-4 ${
                      isMobile ? "text-left" : "text-left"
                    }`}
                  >
                    QROBOTIX: THE ROBOTICS CLUB FOR EDUCATIONAL INSTITUTIONS
                  </div>
                  <p className="mb-4 text-[#5E5E5E]">
                    QROBOTIX is a robotics club that provides educational
                    institutions with the resources and support they need to
                    start or expand their robotics programs. We offer a variety
                    of services, including:
                  </p>
                  <ul className="list-disc pl-4 mb-4 justify-center text-[#5E5E5E]">
                    <li className="mb-2">
                      <span className="font-bold">Curriculum development:</span>{" "}
                      We can help you develop a robotics curriculum that is
                      tailored to the needs of your students.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">Robotics kits:</span> We offer
                      a variety of robotics kits that are perfect for beginners
                      and experienced students alike.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">Workshops:</span> We offer
                      workshops on a variety of robotics topics, such as
                      programming, electronics, and mechanical design.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">Competition support:</span> We
                      can help you prepare your students for robotics
                      competitions.
                    </li>
                  </ul>
                </div>
              </div>

              {/* Image Section */}
              <div
                className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"} ${
                  isMobile ? "h-[35vh]" : ""
                }`}
              >
                <div
                  className={`mb-4 ${
                    isMobile
                      ? "w-full"
                      : "w-[100%] flex flex-col justify-center items-center ms-[40px]"
                  }`}
                >
                  <img
                    src={qrobotixForInstitutionalMsembership}
                    alt="QROBOTIX Image"
                    className={`mx-auto h-auto w-[100%]`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`m-auto flex flex-col m-auto items-center justify-center mt-[80px]  ${
              isMobile ? "w-[90vw]" : "lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]"
            }`}
          >
            <CenteredText text="BENEFITS" />
            <div
              className={`flex ${
                isMobile ? "flex-col items-center" : "flex-row items-start"
              } gap-5 w-[100%] justify-center  mb-[40px]`}
            >
              {/* Content Section */}
              <div
                className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"}`}
              >
                <div
                  className={`mb-4 text-[#5E5E5E] ${
                    isMobile
                      ? "w-full"
                      : "w-[100%] flex flex-col justify-centerx ms-[40px]"
                  }`}
                >
                  <p className="text mb-4 text-left">
                    In addition to these services, QROBOTIX also offers a
                    variety of benefits to educational institutions, including:
                  </p>
                  <ul className="list-disc pl-4 mb-4 justify-center">
                    <li className="mb-2">
                      <span className="font-bold">
                        Increased student engagement:
                      </span>{" "}
                      Robotics is a fun and engaging way to learn STEM skills.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">
                        Improved problem-solving skills:
                      </span>{" "}
                      Robotics teaches students how to identify and solve
                      problems.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">Collaboration skills:</span>{" "}
                      Robotics requires students to work together as a team.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">Career preparation:</span>{" "}
                      Robotics is a growing field with many job opportunities.
                    </li>
                  </ul>
                  <p className="mb-4">
                    <span className="font-bold">
                      Industrial infrastructure:
                    </span>{" "}
                    QROBOTIX has access to a state-of-the-art industrial
                    infrastructure, including machine shops, 3D printers, and
                    laser cutters. This infrastructure allows students to build
                    and test their robots using the same tools and equipment
                    that are used in industry.
                  </p>
                  <ul className="list-disc pl-4 mb-4 justify-center">
                    <li className="mb-2">
                      <span className="font-bold">Design lab facilities</span>{" "}
                      QROBOTIX has a dedicated design lab facility that is
                      equipped with all the necessary software and hardware for
                      students to design and prototype their robots. This
                      facility provides students with a space to work
                      independently or collaborate with their peers.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">
                        AI & Quantum Technology support:
                      </span>{" "}
                      QROBOTIX is supported by an AI & Quantum Technology
                      company, which provides students with access to
                      cutting-edge AI and quantum technology resources. This
                      support allows students to explore the latest trends in
                      robotics and develop robots that are capable of solving
                      complex problems.
                    </li>
                  </ul>
                  <p className="mb-4">
                    <span className="font-bold">
                      Access to experienced mentors:
                    </span>{" "}
                    QROBOTIX has a team of experienced mentors who are available
                    to provide guidance and support to students. These mentors
                    come from a variety of backgrounds, including the armed
                    forces. Our mentors have experience in developing and
                    deploying robots for a variety of purposes, including search
                    and rescue, bomb disposal, and surveillance. They can share
                    their experiences with students and help them develop their
                    own robotics projects.
                  </p>
                  <ul className="list-disc pl-4 mb-4 justify-center">
                    <li className="mb-2">
                      <span className="font-bold">
                        Opportunities for collaboration:
                      </span>{" "}
                      QROBOTIX provides students with opportunities to
                      collaborate with other students from different schools and
                      countries. This collaboration allows students to learn
                      from each other and share their ideas.
                    </li>
                    <li className="mb-2">
                      <span className="font-bold">Access to competitions:</span>{" "}
                      QROBOTIX helps students prepare for and compete in
                      robotics competitions. These competitions provide students
                      with a chance to showcase their skills and win prizes.
                    </li>
                  </ul>
                  <p>
                    If you are interested in learning more about how QROBOTIX
                    can help your educational institution, please contact us
                    today. We would be happy to discuss your specific needs and
                    how we can help you create a successful robotics program.{" "}
                  </p>
                </div>
              </div>

              {/* Image Section */}
              <div
                className={`video-container mt-[40px]  ${
                  isMobile ? "w-[90%]" : "w-[100%]"
                } ${isMobile ? "h-[35vh]" : ""}`}
              >
                <div
                  className={`mb-4 ${
                    isMobile
                      ? "w-full"
                      : "w-[100%] flex flex-col justify-center items-center ms-[40px]"
                  }`}
                >
                  <img
                    src={benifitsInstitutional}
                    alt="QROBOTIX Image"
                    className={`mx-auto h-auto w-[100%]`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`m-auto flex flex-col m-auto items-center justify-center mt-[80px] ${
              isMobile ? "w-[90vw]" : "lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]"
            }`}
          >
            <CenteredText text="Why to connnect" />
            <div
              className={`flex text-[#5E5E5E] ${
                isMobile ? "flex-col items-center" : "flex-row items-start"
              } gap-5 w-[100%] justify-center  mb-[40px]`}
            >
              {/* Content Section */}
              <div
                className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"}`}
              >
                <div
                  className={`mb-4 ${
                    isMobile
                      ? "w-full"
                      : "w-[100%] flex flex-col justify-centerx ms-[40px]"
                  }`}
                >
                  <p className="mb-4 font-bold">
                    Contact us today to learn more about how QROBOTIX can help
                    your educational institution.
                  </p>
                  <p className="mb-4 font-bold">
                    We are proud to offer our students the opportunity to learn
                    from mentors who have served in the armed forces. Our
                    mentors have a wealth of experience in developing and
                    deploying robots, and they can share their knowledge and
                    expertise with our students.
                  </p>
                  <p className="mb-4 font-bold">
                    This is a unique opportunity for our students to learn from
                    some of the best minds in the field of robotics.We believe
                    that our students can benefit greatly from the experience
                    and insights of our mentors. Our mentors can help our
                    students develop the skills they need to succeed in the
                    field of robotics.
                  </p>
                  <p className="mb-4 font-bold">
                    They can also help our students develop a strong work ethic
                    and a sense of discipline. We are excited to offer this
                    opportunity to our students, and we believe that it will be
                    a valuable experience for them.
                  </p>
                </div>
              </div>
              {/* Image Section */}
              <div
                className={`video-container mt-[40px]  ${
                  isMobile ? "w-[90%]" : "w-[100%]"
                } ${isMobile ? "h-[35vh]" : ""}`}
              >
                <div
                  className={`mb-4 ${
                    isMobile
                      ? "w-full"
                      : "w-[100%] flex flex-col justify-center items-center ms-[40px]"
                  }`}
                >
                  <img
                    src={whyConnect}
                    alt="QROBOTIX Image"
                    className={`mx-auto h-auto w-[100%]`}
                  />
                </div>
              </div>
            </div>
            <CenteredText text="Contact Us" />
            <p
              className="text-center mt-10 w-full"
              style={{ color: "#1B1B1B" }}
            >
              QUERY FOR ROBOTICS FOR INSTITUTION
            </p>
            <Button
              variant="contained"
              onClick={openWhatsapp}
              sx={{
                backgroundColor: "#122cdb",
                color: "white",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "4px",
                padding: "10px 25px",
                margin: "20px 0 0 0 ",
              }}
              className="gap-2"
            >
              <FaWhatsapp
                color="green"
                style={{ width: "25px", height: "25px", margin: "3px" }}
              />
              Message us on Whatsapp
            </Button>
            <p
              className="text-center mt-10 w-full"
              style={{ color: "#1B1B1B" }}
            >
              QROBOTIX
            </p>
            <p
              className="text-center w-full"
              style={{
                color: "#5E5E5E",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              Mail to{" "}
              <a
                href="mailto:contactus@qrobotix.com"
                style={{ color: "#122cdb" }}
              >
                contactus@qrobotix.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InstitutionalMembership;
