import React, { useEffect, useState } from "react";
import Heading from "../Components/Heading";
import qrobotixBackgroundVideo from "../asset/BackgroundVideo/HomePageBackground.mp4";
import vission1 from "../asset/Home/vision1.webp";
import vission2 from "../asset/Home/vission2.webp";
import Footer from "../Components/Footer";
import TextField from "@mui/material/TextField";
import facebookSvg from "../asset/logo/facebook.svg";
import instagramSvg from "../asset/logo/instagram.svg";
import { FaChevronDown, FaChevronUp, FaWhatsapp } from "react-icons/fa";
import linkedinSvg from "../asset/logo/linkedin.svg";
import { Button, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../BaseURL/api";
import { message } from "antd";

const Home = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [fullName, setFullName] = useState("");
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");
  const [width, setWidth] = useState(50);
  const [showFullSchedule, setShowFullSchedule] = useState(false);
  const [subscribe, setSubscribe] = useState(false);

  const officeHours = [
    { day: "Mon", hours: "09:00 am – 05:00 pm" },
    { day: "Tue", hours: "09:00 am – 05:00 pm" },
    { day: "Wed", hours: "09:00 am – 05:00 pm" },
    { day: "Thu", hours: "09:00 am – 05:00 pm" },
    { day: "Fri", hours: "09:00 am – 05:00 pm" },
    { day: "Sat", hours: "Closed" },
    { day: "Sun", hours: "Closed" },
  ];

  const today = new Date().toLocaleDateString("en-US", { weekday: "short" });
  console.log(today);
  const todaySchedule = officeHours.find((item) => item.day === today);

  const openWhatsapp = () => {
    window.open("https://wa.me/919019733008", "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    if (window.innerWidth <= 1024) {
      setWidth(100);
    } else if (window.innerWidth <= 1850) {
      setWidth(90);
    }
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth, window.innerHeight]);

  const handleSubscribeMeToggle = () => {
    setSubscribe(!subscribe);
  };

  const [errors, setErrors] = useState({
    fullName: false,
    message: false,
    email: false,
  });

  const [errorsMessage, setErrorsMessage] = useState({
    firstName: "Name is required",
    message: "message is required",
    email: "Email is required",
  });

  const validateForm = () => {
    let valid = true;
    const errorsCopy = { ...errors };
    const errorsMessageCopy = { ...errorsMessage };

    if (!fullName.trim()) {
      errorsCopy.fullName = true;
      valid = false;
    } else {
      errorsCopy.fullName = false;
    }

    if (!feedback.trim()) {
      errorsCopy.feedback = true;
      valid = false;
    } else {
      errorsCopy.feedback = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === "") {
      errorsCopy.email = true;
    } else {
      if (!emailRegex.test(email.trim())) {
        errorsCopy.email = true;
        errorsMessageCopy.email = "Please enter a valid email";
        valid = false;
      } else {
        errorsCopy.email = false;
      }
    }

    setErrors(errorsCopy);
    setErrorsMessage(errorsMessageCopy);
    return valid;
  };

  const handleContactUs = async () => {
    if (!validateForm()) {
      return; // If form is not valid, do not proceed
    }

    const signUpData = {
      fullName: fullName,
      message: feedback,
      email: email,
      subscribe: subscribe,
    };

    try {
      const response = await api.post("/feedback", signUpData);
      console.log(response);
      if (response.status === 201) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          message.destroy();
          setEmail("");
          setFullName("");
          setFeedback("");
          setSubscribe(false);
          message.success(
            "Thank you for your feedback. We will get back to you soon."
          );
        } else {
          const responseText = await response.text();
          message.destroy();
          message.error(responseText);
        }
      } else {
        const errorResponse = await response.json();
        const errorMessage =
          errorResponse.message || "An unexpected error occurred";
        message.destroy();
        message.error(errorMessage);
      }
      // message.error('Something Went Wrong. Please Try Again Later');
    } catch (error) {
      message.destroy();
      message.error("An error occurred during sign-up. Please try again");
    }
  };
  return (
    <div className="flex flex-col min-h-screen justify-between w-full">
      <div className="">
        <div className="relative h-[90vh] max-[768px]:h-[60vh] max-w-[100vw]">
          <video
            className="absolute top-0 left-0 w-full h-[80vh] max-[768px]:h-[50vh] object-fill bg-black"
            autoPlay
            loop
            muted
          >
            <source src={qrobotixBackgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 flex flex-col justify-end">
            <div
              className={`flex flex-col items-center justify-center bg-[#122CDB] rounded-full p-8 my-[5vh] ${
                !isMobile ? "mx-[70vw]" : "mx-auto"
              } h-[250px] w-[250px] max-[768px]:h-[150px] max-[768px]:w-[150px]`}
            >
              <h1
                className="text-white text-2xl font-bold text-center  max-[768px]:text-sm"
                style={{
                  textShadow: "2px 2px 0px rgba(255, 255, 255, 0.3)",
                }}
              >
                QROBOTIX CREATE, INNOVATE, DOMINATE
              </h1>
              <button
                className="bg-white text-[#303030] font-bold py-4 mt-[10px] px-4 text-lg rounded uppercase  max-[768px]:py-1  max-[768px]:px-1  max-[768px]:text-sm  max-[768px]:mt-[4px]"
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                get in touch
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`m-auto flex flex-col m-auto items-center justify-center ${
          isMobile ? "w-[90vw]" : "lg:w-[70vw] xl:w-[60vw] 2xl:w-[50vw]"
        }`}
      >
        <Heading text="Vision & Mission" />
        <div
          className={`flex ${
            isMobile ? "flex-col items-center" : "flex-row items-start"
          } gap-5 w-[100%] justify-center  mb-[40px]`}
        >
          <div className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"} `}>
            <img
              src={vission1}
              alt="QROBOTIX Image"
              className={`mx-auto h-auto w-[100%]`}
            />
          </div>
          <div className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"}`}>
            <div
              className={`mb-4 ${
                isMobile
                  ? "w-full"
                  : "w-[100%] flex flex-col justify-center items-center ms-[40px]"
              }`}
            >
              <div
                className={`text font-normal mb-4 text-center  text-[#1B1B1B]`}
              >
                THE QROBOTIX CLUB
              </div>
              <ul className="list-disc pl-4 mb-4 justify-center text-[#5E5E5E] font-size-[18px]">
                <li className="mb-2">
                  To foster a dynamic and inclusive community of individuals,
                  ranging from students to working professionals, within the
                  QROBOTIX Club.
                </li>{" "}
                <li className="mb-2">
                  We aim to create an environment that promotes learning,
                  innovation, and collaboration in the fields of Advanced
                  Robotics, Humanoids, Quantum Technologies, Drones, and AI
                  Systems.
                </li>{" "}
                <li className="mb-2">
                  By nurturing creativity and providing hands-on experiences, we
                  strive to empower our members to develop cutting-edge
                  prototypes and excel in national and international
                  championships.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`flex ${
            isMobile ? "flex-col-reverse items-center" : "flex-row items-start"
          } gap-5 w-[100%] justify-center mb-[40px]`}
        >
          <div className={` mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"} `}>
            <ul className="list-disc pl-4 mb-4 justify-center text-[#5E5E5E] font-size-[18px]">
              <li className="mb-2">
                Our mission is to engage, inspire, and educate individuals of
                all ages, from 10-year-old students to working professionals,
                through the QROBOTIX Club ( ROBOTICS, DRONES , AI & QUANTUM CLUB
                ), building everything from humanoid robot to next generation
                battlebots
              </li>{" "}
              <li className="mb-2">
                We offer a Design and Development environment where members can
                explore the frontiers of Advanced Robotics, Humanoids, Quantum
                Technologies, Drones, and AI Systems. By providing access to
                state-of-the-art resources, mentorship, and a collaborative
                ecosystem, we aim to foster a passion for innovation and enable
                our members to create prototypes that push the boundaries of
                technological advancements.
              </li>{" "}
              <li className="mb-2">
                Through active participation in national and international
                championships, we seek to showcase our club's expertise and
                contribute to the advancement of these fields on a global scale.
              </li>
            </ul>
          </div>
          <div
            className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"} ${
              isMobile ? "" : ""
            }`}
          >
            <div
              className={`mb-4 ${
                isMobile
                  ? "w-full"
                  : "w-[100%] flex flex-col justify-center items-center ms-[40px]"
              }`}
            >
              <img
                src={vission2}
                alt="QROBOTIX Image"
                className={`mx-auto h-auto w-[100%]`}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex ${
            isMobile ? "flex-col items-center" : "flex-row items-top"
          } gap-5 w-[100%]  justify-center mb-[40px]`}
        >
          <div
            className={`video-container mt-[40px]  ${
              isMobile ? "w-[90%] h-[30vh]" : "w-[100%] h-[25vh]"
            } ${isMobile ? "h-[35vh]" : "h-[30vh]"}`}
          >
            <iframe
              title="YouTube Video"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Z-UcxPR3lqQ"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className={`mt-[40px]  ${isMobile ? "w-[90%]" : "w-[100%]"}`}>
            <div
              className={`mb-4 ${
                isMobile
                  ? "w-full"
                  : "w-[100%] flex flex-col justify-center ms-[40px]"
              }`}
            >
              <div
                className={`text font-normal mb-4 text-center  text-[#1B1B1B]`}
              >
                KEY TAKEWAYS & BENEFITS
              </div>
              <ul
                className={`list-disc mb-4 ${
                  isMobile ? "ms-[20px]" : "ms-[80px]"
                }`}
              >
                <li className="mb-2 font-bold text-[#5E5E5E] font-size-[18px]">
                  Access to industrial infrastructure
                </li>{" "}
                <li className="mb-2 font-bold text-[#5E5E5E] font-size-[18px]">
                  Military veterans guidance & grooming
                </li>{" "}
                <li className="mb-2 font-bold text-[#5E5E5E] font-size-[18px]">
                  Proven industry expertise & practical exposure
                </li>{" "}
                <li className="mb-2 font-bold text-[#5E5E5E] font-size-[18px]">
                  Design, development & process training
                </li>{" "}
                <li className="mb-2 font-bold text-[#5E5E5E] font-size-[18px]">
                  Participate in international robotic championships
                </li>
                <li className="mb-2 font-bold text-[#5E5E5E] font-size-[18px]">
                  Prototype next-generation robots
                </li>
                <li className="mb-2 font-bold text-[#5E5E5E] font-size-[18px]">
                  Build humanoids with AI capabilities
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Heading text="Connect with us" />
        <div className="flex gap-10 my-[40px]">
          <img
            src={facebookSvg}
            alt="Facebook"
            className="cursor-pointer w-[40px] h-[40px] max-[300px]:w-[25px] max-[300px]:h-[25px]"
            onClick={() => {
              window.open(
                "https://www.facebook.com/people/The-Qrobotix-CLUB/100094425152313/",
                "_blank"
              );
            }}
          />
          <img
            src={instagramSvg}
            alt="Facebook"
            className="cursor-pointer w-[40px] h-[40px] max-[300px]:w-[25px] max-[300px]:h-[25px]"
            onClick={() => {
              window.open("https://www.instagram.com/qrobotix/", "_blank");
            }}
          />
          <img
            src={linkedinSvg}
            alt="Facebook"
            className="cursor-pointer w-[44px] h-[44px] max-[300px]:w-[28px] max-[300px]:h-[28px]"
            onClick={() => {
              window.open(
                "https://www.linkedin.com/showcase/95772398/admin/feed/posts/",
                "_blank"
              );
            }}
          />
        </div>
        <Heading text="Contact Us" />
        <p className="text-center mt-10 w-full" style={{ color: "#1B1B1B" }}>
          DROP US A LINE!
        </p>
        <div className="mx-[20px] flex justify-center items-center flex-col min-w-[60%]">
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              variant="filled"
              InputLabelProps={{
                style: { color: "#677379" },
              }}
              InputProps={{
                style: { color: "#677379" },
              }}
              FormHelperTextProps={{
                style: { color: "#677379", margin: 0, padding: 0 },
              }}
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Email *"
              variant="filled"
              type="email"
              InputLabelProps={{
                style: { color: "#677379" },
              }}
              InputProps={{
                style: { color: "#677379" },
              }}
              FormHelperTextProps={{
                style: { color: "#677379", margin: 0, padding: 0 },
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prev) => ({
                  ...prev,
                  email: false,
                }));
                setErrorsMessage((prev) => ({
                  ...prev,
                  email: "Email is required",
                }));
              }}
              error={errors.email}
              helperText={errors.email ? errorsMessage.email : ""}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Message"
              variant="filled"
              multiline
              maxRows={4}
              InputLabelProps={{
                style: { color: "#677379" },
              }}
              InputProps={{
                style: { color: "#677379" },
              }}
              FormHelperTextProps={{
                style: { color: "#677379", margin: 0, padding: 0 },
              }}
              value={feedback}
              onChange={(e) => {
                setFeedback(e.target.value);
              }}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={subscribe}
                onChange={handleSubscribeMeToggle}
                style={{ color: subscribe ? "#035E7B" : "#808A8F" }}
                color="primary"
              />
            }
            label="Sign up for our email list for updates, promotions, and more."
            style={{ color: "#808A8F" }}
          />
          <Button
            variant="contained"
            onClick={handleContactUs}
            sx={{
              backgroundColor: "#122cdb",
              color: "white",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "#122cdb",
              },
              borderRadius: "4px",
              padding: "10px 20px",
              margin: "auto",
            }}
          >
            Sign Up
          </Button>
        </div>
        <p className="text-center mt-10 w-full" style={{ color: "#1B1B1B" }}>
          BETTER YET, SEE US IN PERSON!
        </p>
        <p className="text-center mt-10 w-full" style={{ color: "#5E5E5E" }}>
          We love our customers, so feel free to visit during normal business
          hours.
        </p>
        <Button
          variant="contained"
          onClick={openWhatsapp}
          sx={{
            backgroundColor: "#122cdb",
            color: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#122cdb",
            },
            borderRadius: "4px",
            padding: "10px 25px",
            margin: "20px 0 0 0 ",
          }}
          className="gap-2"
        >
          <FaWhatsapp
            color="green"
            style={{ width: "25px", height: "25px", margin: "3px" }}
          />
          Message us on Whatsapp
        </Button>
        <div className={`${isMobile ? "" : "flex gap-[60px]"}`}>
          <div>
            <p
              className="text-center mt-10 w-full"
              style={{ color: "#1B1B1B" }}
            >
              QROBOTIX CLUB
            </p>
            <p
              className="text-center mt-10 w-[full]"
              style={{ color: "#5E5E5E" }}
            >
              3rd floor , Samaya Towers,
            </p>
            <p className="text-center w-[full]" style={{ color: "#5E5E5E" }}>
              {" "}
              Kodigehalli Main Road Tatanagar, Bengaluru-560092
            </p>
            <p className="text-center mt-10 w-full" style={{ color: "2A38D4" }}>
              <a href="tel:+919019733008" style={{ color: "#122cdb" }}>
                9019733008
              </a>
            </p>
            <p className="text-center w-full" style={{ color: "#122cdb" }}>
              <a
                href="mailto:contactus@qrobotix.com"
                style={{ color: "#122cdb" }}
              >
                contactus@qrobotix.com
              </a>
            </p>
          </div>
          <div>
            <p
              className="text-center mt-10 w-full"
              style={{ color: "#1B1B1B" }}
            >
              HOURS
            </p>
            <div
              className="mt-2"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {todaySchedule ? (
                <div style={{ color: "#5E5E5E" }}>
                  {showFullSchedule ? (
                    officeHours.map((item, index) => (
                      <div
                        key={item.day}
                        className={`${index === 0 ? "cursor-pointer" : ""}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: item.day === today ? "bold" : "normal",
                        }}
                        onClick={() => {
                          if (index === 0) {
                            setShowFullSchedule(!showFullSchedule);
                          }
                        }}
                      >
                        <p>
                          {item.day} {item.hours}{" "}
                        </p>
                        {index === 0 && (
                          <FaChevronUp style={{ marginLeft: "4px" }} />
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      {today === todaySchedule.day && (
                        <div
                          className="cursor-pointer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => setShowFullSchedule(!showFullSchedule)}
                        >
                          <p>
                            {todaySchedule.hours === "Closed"
                              ? ""
                              : "Open Today:"}{" "}
                            {todaySchedule.hours}{" "}
                            {todaySchedule.hours === "Closed" ? " Today" : ""}{" "}
                          </p>
                          <FaChevronDown style={{ marginLeft: "4px" }} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div style={{ color: "#5E5E5E" }}>
                  <div
                    className="cursor-pointer"
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => setShowFullSchedule(!showFullSchedule)}
                  >
                    <p>Closed today</p>
                    <FaChevronDown style={{ marginLeft: "auto" }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-[95vw] mx-[5px] flex justify-center items-center mt-[40px]">
          <iframe
            width="100%"
            height={isMobile ? "400" : "600"}
            loading="lazy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.443045865318!2d77.5737209!3d13.0562572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae199d66c1190d%3A0x1ba426ab21e492c1!2sQuGates%20Technologies!5e0!3m2!1sen!2sin!4v1625831451752!5m2!1sen!2sin"
            title="Google Map"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
