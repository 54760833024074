import React, { useEffect } from "react";
import Heading from "../Components/Heading";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Codeavour from "../asset/blogs/codeavour5.webp";
import CodeavourTheme from "../asset/blogs/codeavour5theme.webp";
import Poster from "../asset/blogs/poster.webp";
import QrobotixTalk from "../asset/blogs/qrobotixtalk.webp";
import QrobotixTalkLarge from "../asset/blogs/qrobotixtalklarge.webp";
import BlogPoster1 from "../asset/blogs/blogposter1.webp";
import BlogPoster2 from "../asset/blogs/blogposter2.webp";
import News from "../asset/blogs/news.webp";
import { useState } from "react";
import ModalComponent from "../Components/ModalComponent";
import BlogPosteModal from "../asset/blogs/blogPosterModal.webp";
import Footer from "../Components/Footer";
import SubscribeComponent from "../Components/SubscribeComponent";

const images = [
  { img: CodeavourTheme, alt: "Codeavour Theme" },
  { img: QrobotixTalk, alt: "Qrobotix Talk" },
  { img: Poster, alt: "Poster" },
  { img: Codeavour, alt: "Codeavour" },
  { img: QrobotixTalkLarge, alt: "Qrobotix Talk Theme" },
];

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button
      className={`${className} absolute top-1/2 left-4 transform -translate-y-1/2 p-2 z-10`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-gray-700"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button
      className={`${className} absolute top-1/2 right-4 transform -translate-y-1/2 p-2 z-10`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-gray-700"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  );
};

const BlogsAndNews = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [openModal, setopenModal] = useState(false);
  const [ispost1, setIspost1] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    console.log(currentSlideIndex);
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show 1 slide at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (current, next) => setCurrentSlideIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
        },
      },
    ],
  };
  const handleClicable = (isPost) => {
    setopenModal(true);
    setIspost1(isPost);
  };
  const handleCloseModal = () => {
    setopenModal(false);
    setIspost1(false);
  };

  return (
    <div className="flex flex-col justify-between min-h-screen w-full">
      <div className="mt-[calc(40px+10em)] max-[1024px]:mt-[calc(40px+6em)] w-full">
        <div className="mb-[56px]">
          <SubscribeComponent />
        </div>
        <Heading text={"VIDEO"} />
        <div className="flex justify-center items-center lg:flex-row flex-col">
          <div className="lg:w-[442px] sm:w-full  h-[620px]">
            <div
              className={`w-full h-full pt-[48px] pb-[24px] ${
                isMobile ? "p-[10px]" : ""
              } `}
            >
              <iframe
                title="video"
                src="https://player.vimeo.com/video/921774504?h=200b5effad&autoplay=0&title=0&portrait=0&byline=0&badge=0"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div
            className={`${
              isMobile ? "p-[15px]" : "pt-[48px] pb-[24px] pr-[48px]"
            }`}
          >
            <p>JOIN THE ROBOTIX REVOLUTION !! </p>
            <span>JOIN QROBOTIX CLUB</span>
          </div>
        </div>

        <div
          className="w-full mb-[56px] mt-[56px]"
          style={{
            backgroundImage: `url(${News})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "56px 0px",
          }}
        >
          <div style={{ margin: "0px 0px 56px" }}>
            <h2 className="text-white text-center sm:text-[48px] text-[25px] font-semibold">
              CLUB NEWS DESK
            </h2>
          </div>
          <div className="flex justify-center items-center">
            <div
              className={`${
                isMobile ? "hidden" : "block"
              } max-w-[1232px] w-full px-4 flex justify-center items-center flex-row gap-4`}
            >
              <div className="w-full md:w-[50%] lg:w-[30%] xl:w-[25%]">
                <div
                  className="w-full h-[140px] bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${BlogPoster1})`,
                    backgroundColor: "#c2c2c2",
                  }}
                ></div>
                <div className="w-full h-[188px] text-black bg-white text-center flex flex-col justify-between px-4 py-3">
                  <p className="text-sm md:text-base">5 July 2024</p>
                  <h4 className="text-lg md:text-xl mb-2">
                    JOIN THE ROBOTICS REVOLUTION
                  </h4>
                  <span
                    className="text-lg text-blue-800 hover:cursor-pointer"
                    onClick={() => handleClicable(false)}
                  >
                    Continue Reading
                  </span>
                </div>
              </div>
              <div className="w-full md:w-[50%] lg:w-[30%] xl:w-[25%]">
                <div
                  className="w-full h-[140px] bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${BlogPoster2})`,
                    backgroundColor: "#c2c2c2",
                  }}
                ></div>
                <div className="w-full h-[188px] text-black bg-white text-center flex flex-col justify-between px-4 py-3">
                  <p className="text-sm md:text-base">1 July 2024</p>
                  <h4 className="text-lg md:text-xl mb-2">
                    JOIN THE QROBOTIC CLUB
                  </h4>
                  <span
                    className="text-lg text-blue-800 hover:cursor-pointer"
                    onClick={() => handleClicable(true)}
                  >
                    Continue Reading
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`${
                isMobile ? "block" : "hidden"
              } max-[500px]:w-[90vw] w-[50vw]  px-4`}
            >
              <Slider {...settings}>
                <div>
                  <div
                    className="w-full h-[40vw] bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${BlogPoster1})`,
                      backgroundColor: "#c2c2c2",
                    }}
                  ></div>
                  <div
                    className={`${
                      isMobile ? "w-full max-h-[10rem]" : "w-full h-full"
                    }  text-black bg-white text-center flex flex-col justify-between px-4 py-3`}
                  >
                    <p className="text-sm md:text-base">5 July 2024</p>
                    <h4 className="text-[0.5rem] md:text-xl mb-2 text-ellipsis overflow-hidden ...">
                      JOIN THE ROBOTICS REVOLUTION
                    </h4>
                    <span
                      className="text-[0.5rem] text-blue-800 hover:cursor-pointer"
                      onClick={() => handleClicable(false)}
                    >
                      Continue Reading
                    </span>
                  </div>
                </div>
                <div>
                  <div
                    className="w-full h-[40vw] bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${BlogPoster2})`,
                      backgroundColor: "#c2c2c2",
                    }}
                  ></div>
                  <div
                    className={`${
                      isMobile ? "w-full max-h-[10rem]" : "w-full h-full"
                    }  text-black bg-white text-center flex flex-col justify-between px-4 py-3`}
                  >
                    <p className="text-sm md:text-base">1 July 2024</p>
                    <h4 className="text-[0.5rem] md:text-xl mb-2 text-ellipsis overflow-hidden ...">
                      JOIN THE QROBOTIC CLUB
                    </h4>
                    <span
                      className="text-[0.5rem] text-blue-800 hover:cursor-pointer"
                      onClick={() => handleClicable(true)}
                    >
                      Continue Reading
                    </span>
                  </div>
                </div>
                {/* Add more slides as needed */}
              </Slider>
            </div>
          </div>
        </div>

        <Heading text={"PHOTO GALLERY"} />

        <div className="flex justify-center items-center  sm:m-5">
          <div className="w-[50%] max-w-screen-lg mx-auto pt-8 relative">
            <Slider {...settings} className="relative">
              {images.map((image, index) => (
                <div key={index} className="w-full h-[600px] ">
                  <img
                    src={image.img}
                    alt={image.alt}
                    className="object-fit w-full h-[400px] sm:h-[600px] md:h-[800px]"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ModalComponent
          modalTitle="JOIN THE ROBOTICS REVOLUTION"
          isPost={ispost1}
          modalImage={ispost1 ? BlogPoster2 : BlogPosteModal}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
      </div>
      <Footer />
    </div>
  );
};

export default BlogsAndNews;
