import React, { useState, useEffect, useRef } from "react";
import {
  FaShoppingCart,
  FaUser,
  FaBars,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import qrobotixImage from "../asset/logo/qrobotix-logo.webp";
import { RxCross1 } from "react-icons/rx";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [showMembershipSublist, setShowMembershipSublist] = useState(false);
  const [scrolled, setScroll] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const userDropdownRef = useRef(null);
  const moreDropdownRef = useRef(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [moreItems, setMoreItems] = useState([
    { label: "Partners & Sponsors", link: "/partners-and-sponsors" },
    { label: "Blogs & News", link: "/blogs-and-news" },
    { label: "Contact Us", link: "/contact-us" },
    { label: "About Us", link: "/about-us" },
    { label: "Fee and Registration", link: "/signup" },
    { label: "Events & Programs", link: "/events-and-programs" },
    { label: "Subscription", link: "/signup" },
    { label: "Registration Form", link: "/signup" },
  ]);
  const [mainItems, setMainItems] = useState([
    { label: "Home", link: "/home" },
    { label: "Qugates", link: "/qugates" },
    { label: "Membership", link: "/membership" },
  ]);
  const mobileViewItems = [
    { label: "Home", link: "/home" },
    { label: "Qugates", link: "/qugates" },
    {
      label: "Membership",
      link: "/membership",
      subItems: [
        { label: "Individual & Group", link: "/individual-group" },
        {
          label: "Institutional Membership",
          link: "/institutional",
        },
      ],
    },
    { label: "Partners & Sponsors", link: "/partners-and-sponsors" },
    { label: "Blogs & News", link: "/blogs-and-news" },
    { label: "Contact Us", link: "/contact-us" },
    { label: "About Us", link: "/about-us" },
    { label: "Fee and Registration", link: "/signup" },
    { label: "Events & Programs", link: "/events-and-programs" },
    { label: "Subscription", link: "/signup" },
    { label: "Registration Form", link: "/signup" },
    { label: "Sign In", link: "/login" },
    { label: "Create Account", link: "/signup" },
  ];

  const toggleMembershipSublist = () =>
    setShowMembershipSublist(!showMembershipSublist);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      if (window.innerWidth > 1024 && window.innerWidth <= 1405) {
        setMainItems([
          { label: "Home", link: "/home" },
          { label: "Qugates", link: "/qugates" },
          { label: "Membership", link: "/membership" },
        ]);
        setMoreItems([
          {
            label: "Partners & Sponsors",
            link: "/partners-and-sponsors",
          },
          { label: "Blogs & News", link: "/blogs-and-news" },
          { label: "Contact Us", link: "/contact-us" },
          { label: "About Us", link: "/about-us" },
          { label: "Fee and Registration", link: "/signup" },
          { label: "Events & Programs", link: "/events-and-programs" },
          { label: "Subscription", link: "/signup" },
          { label: "Registration Form", link: "/signup" },
        ]);
      } else if (window.innerWidth < 1850) {
        setMainItems([
          { label: "Home", link: "/home" },
          { label: "Qugates", link: "/qugates" },
          { label: "Membership", link: "/membership" },
          {
            label: "Partners & Sponsors",
            link: "/partners-and-sponsors",
          },
        ]);
        setMoreItems([
          { label: "Blogs & News", link: "/blogs-and-news" },
          { label: "Contact Us", link: "/contact-us" },
          { label: "About Us", link: "/about-us" },
          { label: "Fee and Registration", link: "/signup" },
          { label: "Events & Programs", link: "/events-and-programs" },
          { label: "Subscription", link: "/signup" },
          { label: "Registration Form", link: "/signup" },
        ]);
      } else if (window.innerWidth <= 2125) {
        setMainItems([
          { label: "Home", link: "/home" },
          { label: "Qugates", link: "/qugates" },
          { label: "Membership", link: "/membership" },
          {
            label: "Partners & Sponsors",
            link: "/partners-and-sponsors",
          },
          { label: "Blogs & News", link: "/blogs-and-news" },
        ]);
        setMoreItems([
          { label: "Contact Us", link: "/contact-us" },
          { label: "About Us", link: "/about-us" },
          { label: "Fee and Registration", link: "/signup" },
          { label: "Events & Programs", link: "/events-and-programs" },
          { label: "Subscription", link: "/signup" },
          { label: "Registration Form", link: "/signup" },
        ]);
      } else if (window.innerWidth <= 2361) {
        setMainItems([
          { label: "Home", link: "/home" },
          { label: "Qugates", link: "/qugates" },
          { label: "Membership", link: "/membership" },
          {
            label: "Partners & Sponsors",
            link: "/partners-and-sponsors",
          },
          { label: "Blogs & News", link: "/blogs-and-news" },
          { label: "Contact Us", link: "/contact-us" },
        ]);
        setMoreItems([
          { label: "About Us", link: "/about-us" },
          { label: "Fee and Registration", link: "/signup" },
          { label: "Events & Programs", link: "/events-and-programs" },
          { label: "Subscription", link: "/signup" },
          { label: "Registration Form", link: "/signup" },
        ]);
      } else {
        setMainItems([
          { label: "Home", link: "/home" },
          { label: "Qugates", link: "/qugates" },
          { label: "Membership", link: "/membership" },
          {
            label: "Partners & Sponsors",
            link: "/partners-and-sponsors",
          },
          { label: "Blogs & News", link: "/blogs-and-news" },
          { label: "Contact Us", link: "/contact-us" },
          { label: "About Us", link: "/about-us" },
        ]);
        setMoreItems([
          { label: "Fee and Registration", link: "/signup" },
          { label: "Events & Programs", link: "/events-and-programs" },
          { label: "Subscription", link: "/signup" },
          { label: "Registration Form", link: "/signup" },
        ]);
      }
    };

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    handleResize(); // Initialize on mount

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigate = (path) => {
    if (path === "/qugates") {
      const externalUrl = `https://qugates.com`;
      // window.location.href = externalUrl;
      window.open(externalUrl, "_blank");
    } else {
      navigate(path);
    }
    setShowMenu(false); // Close the mobile menu after navigation
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (
        moreDropdownRef.current &&
        !moreDropdownRef.current.contains(event.target)
      ) {
        setShowMoreDropdown(false);
      }
      if (
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target)
      ) {
        setShowUserDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => setShowMenu(!showMenu);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const toggleMoreDropdown = () => setShowMoreDropdown(!showMoreDropdown);
  const toggleUserDropdown = () => setShowUserDropdown(!showUserDropdown);
  return (
    <nav className="">
      <div className="flex fixed justify-between items-center p-4 bg-white bg-opacity-75 text-[#1B1B1B] w-full z-50 border border-x-0 border-t-0 border-b-grey">
        <div className="flex items-center m-[20px]">
          {isMobile ? (
            <FaBars onClick={toggleMenu} className="text-xl cursor-pointer" />
          ) : (
            <img
              src={qrobotixImage}
              alt="Logo"
              className={`transition-width duration-500 ease-in-out ${
                scrolled ? "w-[6em]" : "w-[9em]"
              }`}
            />
          )}
        </div>
        <div className={`${isMobile ? "block" : "hidden"}`}>
          {isMobile ? (
            <img
              src={qrobotixImage}
              alt="Logo"
              className={`transition-width duration-500 ease-in-out ${
                scrolled ? "w-[5em]" : "w-[9em]"
              }`}
            />
          ) : (
            ""
          )}
        </div>
        {!isMobile && (
          <div className="hidden md:flex">
            <ul className="list-none flex gap-6 m-0 p-0 text-lg uppercase">
              {mainItems.map((item, index) => (
                <li
                  key={index}
                  className={`cursor-pointer ${
                    item.label === "Membership" ? "relative" : ""
                  }`}
                  ref={item.label === "Membership" ? dropdownRef : null}
                >
                  <div
                    className="cursor-pointer flex items-center hover:text-[#122cdb]"
                    onClick={
                      item.label === "Membership"
                        ? toggleDropdown
                        : () => handleNavigate(item.link)
                    }
                  >
                    {item.label === "Membership" || item.label === "Qugates" ? (
                      item.label
                    ) : (
                      <NavLink
                        style={(e) => {
                          return {
                            fontWeight: e.isActive ? "bold" : "",
                          };
                        }}
                        to={item.link}
                      >
                        {" "}
                        {item.label}
                      </NavLink>
                    )}
                    {item.label === "Membership" &&
                      (showDropdown ? (
                        <FaChevronUp className="ml-3" />
                      ) : (
                        <FaChevronDown className="ml-3" />
                      ))}
                  </div>
                  {item.label === "Membership" && showDropdown && (
                    <ul className="absolute left-0 mt-2 w-64 bg-white border border-gray-200 rounded shadow-lg">
                      <li
                        className="px-4 py-2 text-base cursor-pointer hover:bg-gray-100 uppercase hover:text-[#122cdb]"
                        onClick={() => {
                          handleNavigate("/individual-group");
                          setShowDropdown(false);
                        }}
                      >
                        Individual & Group
                      </li>
                      <li
                        className="px-4 py-2 text-base cursor-pointer hover:bg-gray-100 uppercase hover:text-[#122cdb]"
                        onClick={() => {
                          handleNavigate("/institutional");
                          setShowDropdown(false);
                        }}
                      >
                        Institutional Membership
                      </li>
                    </ul>
                  )}
                </li>
              ))}
              {moreItems.length > 0 && (
                <li className="cursor-pointer relative" ref={moreDropdownRef}>
                  <div
                    className="cursor-pointer flex items-center hover:text-[#122cdb]"
                    onClick={toggleMoreDropdown}
                  >
                    More{" "}
                    {showMoreDropdown ? (
                      <FaChevronUp className="ml-3" />
                    ) : (
                      <FaChevronDown className="ml-3" />
                    )}
                  </div>
                  {showMoreDropdown && (
                    <ul className="absolute left-0 mt-2 w-64 bg-white border rounded shadow-lg">
                      {moreItems.map((item, index) => (
                        <li
                          key={index}
                          className="px-4 py-2 text-base cursor-pointer hover:bg-gray-100 uppercase hover:text-[#122cdb]"
                          onClick={() => {
                            toggleMoreDropdown();
                            handleNavigate(item.link);
                          }}
                        >
                          {item.label}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )}
            </ul>
          </div>
        )}
        <div
          className={`flex items-center ${
            isMobile ? "mr-[10px]" : "mr-[40px]"
          }`}
        >
          {/* {!isMobile && (
              <FaSearch className="ml-4 cursor-pointer hidden md:block text-[#122cdb] text-xl" />
            )} */}
          <FaShoppingCart
            className={`ml-4 cursor-pointer block md:block ${
              isMobile ? "" : "text-[#122cdb]"
            } text-xl`}
          />
          {!isMobile && (
            <div className="relative" ref={userDropdownRef}>
              <FaUser
                className="ml-4 cursor-pointer hidden md:block text-[#122cdb] text-xl"
                onClick={toggleUserDropdown}
              />
              {showUserDropdown && (
                <ul className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
                  <li
                    className="px-4 py-2 text-base cursor-pointer hover:bg-gray-100 uppercase"
                    onClick={() => handleNavigate("/login")}
                  >
                    Sign In
                  </li>
                  <li
                    className="px-4 py-2 text-base cursor-pointer hover:bg-gray-100 uppercase"
                    onClick={() => handleNavigate("/signup")}
                  >
                    Create Account
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
        {isMobile && (
          <div
            className={`absolute left-0 top-0 w-full h-[100vh] bg-white text-[#1B1B1B] ${
              showMenu ? "block" : "hidden"
            }`}
          >
            <div className="px-[10%] pt-[10%]">
              <div className="block  flex justify-end">
                <RxCross1
                  className="ml-4 cursor-pointer text-[#1B1B1B] text-2xl"
                  onClick={() => {
                    toggleMenu();
                    toggleMembershipSublist();
                  }}
                />
              </div>
              <ul className="list-none m-0 p-0 text-lg">
                {mobileViewItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <li
                      className="p-4 border-b border-gray-500 cursor-pointer uppercase flex justify-between items-center"
                      onClick={() => {
                        if (item.label === "Membership") {
                          toggleMembershipSublist();
                        } else {
                          handleNavigate(item.link);
                        }
                      }}
                    >
                      <span>
                        {item.label === "Membership" ||
                        item.label === "Qugates" ? (
                          item.label
                        ) : (
                          <NavLink
                            style={(e) => {
                              return {
                                color: e.isActive ? "#1B1B1B" : "",
                                // fontWeight: e.isActive ? "bold" : "",
                              };
                            }}
                            to={item.link}
                          >
                            {" "}
                            {item.label}
                          </NavLink>
                        )}
                      </span>
                      {item.label === "Membership" &&
                        (showMembershipSublist ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        ))}
                    </li>
                    {item.label === "Membership" && showMembershipSublist && (
                      <ul className="list-none m-0 p-0 text-lg pl-4">
                        {item.subItems.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className="p-4 border-b border-gray-500 cursor-pointer uppercase"
                            onClick={() => {
                              handleNavigate(subItem.link);
                              toggleMembershipSublist();
                            }}
                          >
                            {subItem.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
