import React from "react";
import ImageWithDescription from "../Components/ImageWithDescription";
import Img1 from "../asset/img1.webp";
import Img2 from "../asset/img2.webp";
import Img3 from "../asset/img3.webp";
import Img4 from "../asset/img4.webp";
import Heading from "../Components/Heading";
import Footer from "../Components/Footer";

const AboutUs = () => {
  const handleClick = () => {};

  const imagesAndDescriptions = [
    {
      image: Img1,
      description:
        "A visionary Leader and an experienced solution Architect and a natural Design and Development enthusiast with a demonstrated history of original and patented designs in the information technology and communication domain. An Innovator by instinct and a skilled professional in Artificial Intelligence, Machine Learning, Deep Learning , CNN cloud computing etc. Strong operations professional with a Master's Degree focused in Software Systems from Birla Institute of Technology and Science, Pilani and an MBA in Information systems from Manipal University, with strong foundations in Electrical and Electronic Engg and Aeronautical Engg. A go-getter who has done some unique designs to solve multiple problems spanning across a spectrum of technology platforms and domains",
      name: "WING COMMANDER S SUDHAKARAN (RETD)",
      linkedInUrl:
        "https://www.linkedin.com/in/wing-commander-s-sudhakaran-retd/",
    },
    {
      image: Img2,
      description:
        "Dr. Thiruvonasundari Duraiswamy is a highly accomplished and experienced leader and is a Gold Medalist in M.Tech and a Research Scholar in AI . She is currently the Director DevOps & Infrastructure at QuGates Technologies, where she is responsible for leading the company's DevOps environment end to end.",
      name: "DR THIRUVONASUNDARI DURAISAMY",
      linkedInUrl: "https://www.linkedin.com/in/dtsundari/",
    },
    {
      image: Img3,
      description:
        "Ritu Advani is a passionate STEM educator and entrepreneur with over 8 years of experience. Ritu has coached students for various World level Competitions like : World Robotics Olympiad(WRO) , FIRST Robotics Competitions, VEX Robotics Competition, MIT App Inventor Appathon , Codeavour , Makebot CodingBee , Google Code to Learn. Ritu is also a Mentor Of Change : Atal Innovation Mission ; Initiative by NITI Aayog.",
      name: "MS RITU ADVANI",
      linkedInUrl: "https://www.linkedin.com/in/advani-ritu/",
    },
    {
      image: Img4,
      description:
        "Jasmine is an enthusiastic result-oriented leader with a creative and collaborative mindset. She is among the pioneering women in the Indian Armed Forces and has over 25 years of vast and varied experience in Defence administration, Human Resource services, procurement and international relations. She has proven track record of managing multiple large projects and Service deliveries. She works with a sharp focus on execution and believes that the real path of travelling from Aspirations to Reality lies in Disciplined execution.A people’s person, Jasmine comes with a strong background in international Relationships and broad understanding of the aviation ecosystem.",
      name: "WG CDR JASMINE KAUR (RETD)",
      linkedInUrl: "https://www.linkedin.com/in/wg-cdr-jasmine-kaur-retd/",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen justify-between w-full">
      <div className="mt-[calc(40px+10em)] max-[1024px]:mt-[calc(40px+6em)]">
        <Heading text={"ABOUT US"} />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 px-4 sm:px-8 md:px-12 lg:px-16">
          {imagesAndDescriptions.map((item, index) => (
            <ImageWithDescription
              key={index}
              image={item.image}
              description={item.description}
              name={item.name}
              linkedInUrl={item.linkedInUrl}
              onClick={handleClick}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
