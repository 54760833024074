import React from "react";
import Heading from "../Components/Heading";
import RedirectImage from "../Components/RedirectImage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//Partners AndS ponsorers
import AmritaVishwa from "../asset/partners and sponsorers/amrita-vishwa.webp";
import ChildrenAcademy from "../asset/partners and sponsorers/childrenacademy.webp";
import Codeavour from "../asset/partners and sponsorers/codeavour.webp";
import NewHorizon from "../asset/partners and sponsorers/new horizon.webp";
import Qugates from "../asset/partners and sponsorers/qugates_logo.webp";
import Stempedia from "../asset/partners and sponsorers/stempedia.webp";
//PHOTO GALLERY
import OfficeArea from "../asset/photo gallery/officearea.webp";
import RapidProtoType from "../asset/photo gallery/rapidprototype.webp";
// import RobotArms from '../asset/photo gallery/robot arms.webp';
import RobotMachine from "../asset/photo gallery/robotmachine.webp";
import TableDesign from "../asset/photo gallery/tabledesign.webp";
import WareHouse from "../asset/photo gallery/warehouse.webp";
import Footer from "../Components/Footer";

const images = [
  { img: OfficeArea, alt: "Office Area" },
  { img: RapidProtoType, alt: "Rapid Prototype" },
  { img: RobotMachine, alt: "Robot Machine" },
  { img: TableDesign, alt: "Table Design" },
  { img: WareHouse, alt: "Warehouse" },
];

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button
      className={`${className} absolute top-1/2 left-4 transform -translate-y-1/2 bg-white shadow-lg rounded-full p-2`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-gray-700"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </button>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button
      className={`${className} absolute top-1/2 right-4 transform -translate-y-1/2 bg-white shadow-lg rounded-full p-2`}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-gray-700"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  );
};

const PartnersAndSponsorers = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768, // Breakpoint for medium devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 640, // Breakpoint for small devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const ImagesData = [
    {
      imgSrc: Qugates,
      redirectUrl: "https://qugates.com/",
      altText: "Qugates Logo",
    },
    {
      imgSrc: AmritaVishwa,
      redirectUrl: "https://www.amrita.edu/",
      altText: "Amrita Vishwa Vidyapeetham",
    },
    {
      imgSrc: Codeavour,
      redirectUrl: "https://codeavour.org/",
      altText: "Codeavour",
    },
    {
      imgSrc: Stempedia,
      redirectUrl: "https://thestempedia.com/",
      altText: "Stempedia",
    },
    {
      imgSrc: ChildrenAcademy,
      redirectUrl: "https://www.childrens-academy.in/",
      altText: "Children Academy",
    },
    {
      imgSrc: NewHorizon,
      redirectUrl: "https://newhorizonindia.edu/",
      altText: "New Horizon",
    },
  ];
  //
  return (
    <div className="flex flex-col min-h-screen justify-between w-full">
      <div className="mt-[calc(40px+10em)] max-[1024px]:mt-[calc(40px+6em)] px-[56px]">
        <Heading text={"OUR PATRONS & SPONSORERS"} />
        <div className="pt-[40px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 px-4 sm:px-8 md:px-12 lg:px-16">
          {ImagesData.map((item, index) => (
            <RedirectImage key={index} data={item} />
          ))}
        </div>
        <Heading text={"THE CUTTING EDGE MACHINES THAT WILL MAKE YOUR ROBOT"} />
        <div className="flex flex-col items-center justify-center p-4 sm:p-8 md:p-12 lg:p-16">
          <div className="w-full mb-8 lg:flex lg:items-center lg:justify-center">
            <div className="w-full md:w-[640px] md:max-h-[388px] lg:w-[640px] lg:h-[388px] sm:w-full sm:h-full ">
              <iframe
                title="video"
                src="https://player.vimeo.com/video/842520322?h=9e59f71915&autoplay=0&title=0&portrait=0&byline=0&badge=0"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <div className="text-center lg:w-[640px] lg:max-h-[188px] lg:ml-8 sm:mt-[30px]">
              <p className="text-lg md:text-xl lg:text-2xl overflow-hidden">
                Thats the Kind of Machines that will give wings to your dreams
                and make those special components for your Dreams
              </p>
            </div>
          </div>
        </div>
        <Heading text={"PHOTO GALLERY"} />
        <div className="w-[100%] mx-0 pt-[20px]">
          <Slider {...settings} className="relative">
            {images.map((image, index) => (
              <div key={index} className="w-full">
                <img
                  src={image.img}
                  alt={image.alt}
                  className="fit w-full max-h-[90vh] sm:h-96 md:h-auto"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PartnersAndSponsorers;
