import React from 'react';

const RedirectImage = ({ data }) => {
  const { imgSrc, redirectUrl, altText } = data;

  const handleClick = () => {
    window.open(redirectUrl, '_blank');
  };

  return (
    <div className="max-w-[450px] mx-auto cursor-pointer" onClick={handleClick}>
      <img src={imgSrc} alt={altText} />
    </div>
  );
};

export default RedirectImage;
