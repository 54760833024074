import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SubscribeComponent from "../Components/SubscribeComponent";
import CenteredText from "../Components/Heading";
import { Button } from "@mui/material";
import { Footer } from "../Components/Footer";
import { FaWhatsapp } from "react-icons/fa";

const ContactUs = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showFullSchedule, setShowFullSchedule] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    console.log(isMobile);
    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const officeHours = [
    { day: "Mon", hours: "09:00 am – 05:00 pm" },
    { day: "Tue", hours: "09:00 am – 05:00 pm" },
    { day: "Wed", hours: "09:00 am – 05:00 pm" },
    { day: "Thu", hours: "09:00 am – 05:00 pm" },
    { day: "Fri", hours: "09:00 am – 05:00 pm" },
    { day: "Sat", hours: "Closed" },
    { day: "Sun", hours: "Closed" },
  ];

  const today = new Date().toLocaleDateString("en-US", { weekday: "short" });
  const todaySchedule = officeHours.find((item) => item.day === today);

  const openWhatsapp = () => {
    window.open("https://wa.me/919019733008", "_blank");
  };
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <div className="mb-[20px] ml-[10px] mr-[10px]">
        <div className="mt-[calc(40px+10em)] max-[1024px]:mt-[calc(40px+6em)]">
          <SubscribeComponent />
        </div>
        <div className="mt-[80px] flex justify-center items-center flex-col">
          <CenteredText text="Contact Us" />
          <p
            className="text-center mt-10 w-full tracking-widest"
            style={{ color: "#1B1B1B" }}
          >
            BETTER YET, SEE US IN PERSON!
          </p>
          <p
            className="text-center mt-[24px] w-full mb-[15px]"
            style={{ color: "#5E5E5E" }}
          >
            We love our customers, so feel free to visit during normal business
            hours.
          </p>
          <Button
            variant="contained"
            onClick={openWhatsapp}
            sx={{
              backgroundColor: "#122cdb",
              color: "white",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
              padding: "10px 25px",
              margin: "20px 0 0 0 ",
            }}
            className="gap-2"
          >
            <FaWhatsapp
              color="green"
              style={{ width: "25px", height: "25px", margin: "3px" }}
            />
            Message us on Whatsapp
          </Button>
          <p className="text-center mt-10 w-full" style={{ color: "#1B1B1B" }}>
            QROBOTIX CLUB
          </p>
          <p
            className="text-center mt-10 w-full text-[18px]"
            style={{ color: "#5E5E5E" }}
          >
            3rd floor , Samaya Towers, Kodigehalli Main Road Tatanagar,
            Bengaluru-560092
          </p>
          <p className="text-center mt-10 w-full" style={{ color: "2A38D4" }}>
            <a href="tel:+919019733008" style={{ color: "#122cdb" }}>
              9019733008
            </a>
          </p>
          <p className="text-center w-full" style={{ color: "2A38D4" }}>
            <a
              href="mailto:contactus@qrobotix.com"
              style={{ color: "#122cdb" }}
            >
              contactus@qrobotix.com
            </a>
          </p>
          <p className="text-center mt-10 w-full" style={{ color: "#5E5E5E" }}>
            HOURS
          </p>
          <div
            className="mt-2"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {todaySchedule ? (
              <div style={{ color: "#5E5E5E" }}>
                {showFullSchedule ? (
                  officeHours.map((item, index) => (
                    <div
                      key={item.day}
                      className={`${index === 0 ? "cursor-pointer" : ""}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: item.day === today ? "bold" : "normal",
                      }}
                      onClick={() => {
                        if (index === 0) {
                          setShowFullSchedule(!showFullSchedule);
                        }
                      }}
                    >
                      <p>
                        {item.day} {item.hours}{" "}
                      </p>
                      {index === 0 && (
                        <FaChevronUp style={{ marginLeft: "4px" }} />
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    {today === todaySchedule.day && (
                      <div
                        className="cursor-pointer"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => setShowFullSchedule(!showFullSchedule)}
                      >
                        <p>
                          {todaySchedule.hours === "Closed"
                            ? ""
                            : "Open Today:"}{" "}
                          {todaySchedule.hours}{" "}
                          {todaySchedule.hours === "Closed" ? " Today" : ""}{" "}
                        </p>
                        <FaChevronDown style={{ marginLeft: "4px" }} />
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div style={{ color: "#5E5E5E" }}>
                <div
                  className="cursor-pointer"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => setShowFullSchedule(!showFullSchedule)}
                >
                  <p>Closed today</p>
                  <FaChevronDown style={{ marginLeft: "auto" }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
