import React from "react";
import PropTypes from "prop-types";

const CenteredText = ({ text }) => {
  return (
    <div className="flex items-center justify-center">
      <h1
        className="text-3xl leading-10 font-fjalla-one uppercase text-center text-[#1B1B1B]"
        style={{
          textShadow: "2px 2px 0px rgba(0, 0, 0, 0.2)",
        }}
      >
        {text}
      </h1>
    </div>
  );
};

CenteredText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CenteredText;
