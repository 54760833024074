import { Button } from "@mui/material";
import PropTypes from "prop-types";

const Events = ({ heading, src, onClick }) => {
  return (
    <div className="flex flex-col items-center">
      <div className=" font-bold uppercase text-center text-[#122cdb] mb-4">
        {heading}
      </div>
      <img src={src} alt={heading} className="mb-4 w-[100%]" />
      <Button
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: "#122cdb",
          color: "white",
          boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "#122cdb",
          },
          borderRadius: "4px",
          padding: "10px 25px",
        }}
      >
        Register Now
      </Button>
    </div>
  );
};

Events.propTypes = {
  heading: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Events;
