import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubscribtionDetail = ({ heading, description }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/signup");
  };

  return (
    <div className="flex flex-col md:flex-row justify-center items-center text-base md:text-lg p-4 md:p-8 mb-4 md:mb-6">
      <div className="md:w-1/2 md:pr-8 lg:w-full">
        <h4 className="mb-4 text-[#1B1B1B]">{heading}</h4>
        <p className="lg:w-full text-[#5E5E5E]">{description}</p>
      </div>
      <div className="mt-4 md:mt-0 md:w-1/2 flex justify-center md:justify-start">
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#122cdb",
            color: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "#122cdb",
            },
            borderRadius: "4px",
            padding: "10px 25px",
            margin: "20px 0 0 0",
          }}
          onClick={handleButtonClick}
        >
          SUBSCRIBE
        </Button>
      </div>
    </div>
  );
};

export default SubscribtionDetail;
