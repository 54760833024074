import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from '../BaseURL/api';
import { message } from 'antd';

const SubscribeComponent = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    email: false,
  });
  const [errorsMessage, setErrorsMessage] = useState({
    email: 'Email is required',
  });
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const validateForm = () => {
    let valid = true;

    const errorsCopy = { ...errors };
    const errorsMessageCopy = { ...errorsMessage };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === '') {
      errorsCopy.email = true;
    } else {
      if (!emailRegex.test(email.trim())) {
        errorsCopy.email = true;
        errorsMessageCopy.email = 'Please enter a valid email';
        valid = false;
      } else {
        errorsCopy.email = false;
      }
    }
    setErrors(errorsCopy);
    setErrorsMessage(errorsMessageCopy);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // If form is not valid, do not proceed
    }

    const signUpData = {
      email: email,
    };

    try {
      const response = await api.post('/subscribe', signUpData);
      console.log(response);
      if (response.status === 201) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          message.destroy();
          setEmail('');
          message.success('Subscribed successfully.');
        } else {
          const responseText = await response.text();
          message.destroy();
          message.error(responseText);
        }
      } else {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.message || 'An unexpected error occurred';
        message.destroy();
        message.error(errorMessage);
      }
      // message.error('Something Went Wrong. Please Try Again Later');
    } catch (error) {
      message.destroy();
      message.error('An error occurred during sign-up. Please try again');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <h1 className="text-4xl font-bold text-center" style={{ color: '#1B1B1B' }}>
        SUBSCRIBE
      </h1>
      <p className="text-center mt-10 w-full" style={{ color: '#5E5E5E' }}>
        Get 10% off your first purchase when you sign up for our newsletter!
      </p>
      <div className="flex gap-2 mt-6 items-center">
        <TextField
          label="Email *"
          variant="filled"
          type="email"
          InputLabelProps={{
            style: { color: '#677379' },
          }}
          InputProps={{
            style: { color: '#677379' },
          }}
          FormHelperTextProps={{
            style: { color: '#677379', margin: 0, padding: 0 },
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrors((prev) => ({
              ...prev,
              email: false,
            }));
            setErrorsMessage((prev) => ({
              ...prev,
              email: 'Email is required',
            }));
          }}
          error={errors.email}
          helperText={errors.email ? errorsMessage.email : ''}
          className={`${isMobile ? 'w-[90vw]' : 'w-[25vw]'} bg-white`}
        />
        {!isMobile && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: '#122cdb',
              color: 'white',
              boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: '#122cdb',
              },
              borderRadius: '4px',
              padding: '10px 25px',
            }}
          >
            Sign Up
          </Button>
        )}
      </div>
      {isMobile && (
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            width: '90vw',
            backgroundColor: '#122cdb',
            color: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: '#122cdb',
            },
            borderRadius: '4px',
            padding: '10px 20px',
            margin: '20px',
          }}
        >
          Sign Up
        </Button>
      )}
    </div>
  );
};

export default SubscribeComponent;
