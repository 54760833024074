import { Avatar } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

const ImageWithDescription = ({
  image,
  description,
  name,
  linkedInUrl,
  onClick,
}) => {
  const handleButtonClick = () => {
    window.open(linkedInUrl, "_blank");
  };

  return (
    <div className="max-w-[580px] mx-auto px-4 py-8">
      <div className="flex items-center justify-center mb-4">
        <Avatar
          alt="Avatar"
          src={image}
          sx={{ width: "100%", height: "auto", maxWidth: 570, maxHeight: 570 }}
        />
      </div>
      <h4 className="text-center mb-2 text-[#1B1B1B] tracking-widest">
        {name}
      </h4>
      <div>
        <p className="text-center text-[16px] text-[#5E5E5E]">{description}</p>
      </div>
      <div className="flex justify-center mt-4">
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#122cdb",
            color: "white",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            padding: "10px 25px",
            margin: "20px 0 0 0 ",
          }}
          onClick={handleButtonClick}
        >
          ABOUT
        </Button>
      </div>
    </div>
  );
};

export default ImageWithDescription;
