import React, { useState, useEffect } from "react";
import { Button, Modal, Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FaFacebook } from "react-icons/fa"; // Correct import
import { RiTwitterXFill } from "react-icons/ri"; // Correct import

const ModalComponent = ({
  modalTitle,
  isPost,
  modalImage,
  openModal,
  handleCloseModal,
}) => {
  const theme = useTheme();
  const modalTextContent = isPost
    ? `THE ROBOTICS & DRONES     CLUB

What's New
Join QROBOTIX:   Where Dreams Take Flight!

Hello,

Welcome to the   exhilarating world of QROBOTIX! We are thrilled to invite you to be a part of   our extraordinary club that breathes life into your dreams and aspirations.   Whether you're a seasoned tech enthusiast or just beginning to explore the   wonders of robotics, drones, and AI, QROBOTIX is the place where your passion   can soar to new heights!

Who are we?
 QROBOTIX is a vibrant and inclusive community dedicated to creating a   collaborative environment for tech geeks like you. We believe in nurturing   innovation, fostering learning, and embracing the limitless possibilities of   advanced robotics, humanoids, quantum technologies, drones, and AI systems.

What can you   expect?
 As a member of QROBOTIX, you'll embark on a thrilling journey filled with   opportunities to learn, create, and excel. Here's what awaits you:

Cutting-edge   Workshops: Dive deep into the realms of robotics, drones, and AI through our   hands-on workshops led by industry experts. Gain practical skills and   knowledge that will empower you to turn your ideas into reality.

Inspiring   Projects: Unleash your creativity by collaborating on groundbreaking projects   that push the boundaries of technological advancements. Join forces with   fellow members and bring innovative concepts to life.

National and   International Championships: Showcase your talent and compete on the grand   stage! As a QROBOTIX member, you'll have the chance to participate in   prestigious national and international championships, where you can put your   skills to the test and make your mark.

Exclusive   Networking: Connect with like-minded individuals who share your passion for   technology. Engage in lively discussions, exchange ideas, and forge valuable   connections that will propel your journey forward.

Resource Hub:   Access a treasure trove of educational resources, research papers, and   technical documentation that will keep you at the forefront of the latest   advancements in the field. Stay ahead of the curve and continue to grow as a   tech geek.

Join us now!
 Don't miss out on the exhilarating opportunities that await you at QROBOTIX.   Take the first step toward an awe-inspiring adventure by joining our network   of tech enthusiasts. Visit our website [Insert Website Link] to become a   member and unlock a world of possibilities!

Together, let's   turn your dreams into reality, create the next generation of robotics, and   shape a future where technology knows no bounds!

Warm regards,

QROBOTIX Team

P.S. Follow us on   social media  to stay updated on the latest   news, events, and fascinating insights in the world of robotics, drones, and   AI. Join our vibrant community today!`
    : "";

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is less than or equal to 'sm'
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openModal); // Set modal state based on prop
  }, [openModal]); // Re-run effect whenever openModal changes

  // const handleOpen = () => {
  //   setOpen(true);
  //   if (openModal) {
  //     openModal(); // Optional callback when opening modal
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
    if (handleCloseModal) {
      handleCloseModal(); // Optional callback when closing modal
    }
  };
  const shareOnFacebook = () => {
    // Replace with your actual post URL and message
    const url = `${window.location.origin}`; // Replace with your post URL
    const text = "Check out this amazing post!"; // Replace with your share message
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}&quote=${encodeURIComponent(text)}`,
      "_blank"
    );
  };

  const shareOnTwitter = () => {
    // Replace with your actual post URL and message
    const url = `${window.location.origin}`; // Replace with your post URL
    const text = "Check out this amazing post!"; // Replace with your share message
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(text)}`,
      "_blank"
    );
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullScreen={fullScreen}
        style={{ overflow: "auto" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%", // Adjust width based on screen size
            maxWidth: 800, // Maximum width of the modal
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto", // Enable scrolling for overflow content
            maxHeight: "80vh", // Limit max height of modal content
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-[#1e37dd]"
          >
            {modalTitle}
          </Typography>
          {modalImage && (
            <img
              src={modalImage}
              style={{ width: "100%", marginTop: 10, borderRadius: 5 }}
              alt=""
            />
          )}
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, whiteSpace: "pre-wrap" }}
            className="text-[#5E5E5E] font-bold"
          >
            {modalTextContent}
          </Typography>
          <div className="flex justify-start items-center">
            <Typography className="text-[#000000] font-bold text-sm pt-4">
              Share This Post:
            </Typography>
            <div className="flex gap-2 justify-center items-center mt-3 ms-2">
              <FaFacebook
                className="text-[#0000ffa4] cursor-pointer"
                onClick={shareOnFacebook}
              />
              <RiTwitterXFill
                className="cursor-pointer"
                onClick={shareOnTwitter}
              />
            </div>
          </div>
          <Button onClick={handleClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalComponent;
