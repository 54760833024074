import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Navbar/Navbar"; // Assuming your custom navbar component file is named Navbar.js
import Home from "./Routes/Home"; // Adjust the import statement to match the actual file structure
import ContactUs from "./Routes/ContactUs";
import InstitutionalMembership from "./Routes/InstitutionalMembership";
import EventsAndProgram from "./Routes/EventsAndProgram";
import AboutUs from "./Routes/AboutUs";
import PartnersAndSponsorers from "./Routes/PartnersAndSponsorers";
import IndividualAndGroup from "./Routes/IndividualAndGroup";
import BlogsAndNews from "./Routes/BlogsAndNews";
import Login from "./Routes/Login";
import Signup from "./Routes/SignUp";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/individual-group" element={<IndividualAndGroup />} />
        <Route path="/institutional" element={<InstitutionalMembership />} />
        <Route
          path="/partners-and-sponsors"
          element={<PartnersAndSponsorers />}
        />
        <Route path="/blogs-and-news" element={<BlogsAndNews />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/events-and-programs" element={<EventsAndProgram />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
