import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
// import { useAuth } from "../Authorization/AuthProvider";
import LoginBackground from "../asset/AuthenticationImages/LoginBackground.svg";
import Qrobotix from "../asset/logo/qrobotix.png";
import { useNavigate } from "react-router-dom";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { message } from "antd";

const Login = () => {
  const navigate = useNavigate();
  //   const auth = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMeToggle = () => {
    setRememberMe(!rememberMe);
  };

  const validateForm = () => {
    let valid = true;
    if (email.trim() === "") {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }
    if (password.trim() === "") {
      setPasswordError(true);
      valid = false;
    } else {
      setPasswordError(false);
    }
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    // const loginData = {
    //   username: email,
    //   password: password,
    // };

    try {
      //   await auth.employeeLogin(loginData);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${LoginBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: isSmallScreen ? "150%" : "100%",
        backgroundPosition: "center",
        padding: isSmallScreen ? "20px" : "0",
      }}
    >
      <Grid item xs={12} sm={8} md={6} lg={5} style={{ maxWidth: "550px" }}>
        <Paper
          elevation={3}
          style={{
            padding: "48px 24px",
            borderRadius: "4px",
            backgroundColor: "white",
          }}
        >
          <Grid container justifyContent="center">
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", justifyContent: "center" }}
            >
              <img
                src={Qrobotix}
                alt="Company Logo"
                style={{
                  width: "142px",
                  height: "90px",
                  margin: "auto",
                }}
              />
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#242C30",
                  fontSize: 24,
                  fontFamily: "Archivo",
                  fontWeight: "700",
                  marginBottom: "64px",
                  marginTop: "8px",
                  lineHeight: 1,
                  wordWrap: "break-word",
                }}
              >
                Log in
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="email"
                  name="email"
                  label="Email *"
                  variant="filled"
                  autoFocus
                  value={email}
                  error={emailError}
                  helperText={emailError ? "Email is required" : ""}
                  InputLabelProps={{
                    style: { color: "#677379" },
                  }}
                  InputProps={{
                    style: { color: "#677379" },
                  }}
                  FormHelperTextProps={{
                    style: { color: "#677379", margin: 0, padding: 0 },
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: "4px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div
                style={{
                  color: "#122cdb",
                  fontSize: 14,
                  fontFamily: "Inter",
                  fontWeight: "500",
                  lineHeight: 1,
                  wordWrap: "break-word",
                  cursor: "pointer",
                }}
                onClick={() => {
                  message.destroy();
                  message.info("Please contact admin");
                }}
              >
                Forgot password
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="password"
                  name="password"
                  label="Password *"
                  variant="filled"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  error={passwordError}
                  helperText={passwordError ? "Password is required" : ""}
                  InputLabelProps={{
                    style: { color: "#677379" },
                  }}
                  FormHelperTextProps={{
                    style: { color: "#677379", margin: 0, padding: 0 },
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  InputProps={{
                    style: { color: "#677379" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handlePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "16px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMeToggle}
                    style={{ color: rememberMe ? "#122cdb" : "#808A8F" }}
                    color="primary"
                  />
                }
                label="Remember me"
                style={{ color: "#808A8F" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                style={{
                  marginTop: "32px",
                  padding: "16px",
                  backgroundColor: "#122cdb",
                  color: "#F2F2F3",
                  textTransform: "none",
                }}
                onClick={handleSubmit}
              >
                Log in
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="body2" style={{ marginRight: "4px" }}>
                  Don’t have a Qrobotix account?
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#122cdb", cursor: "pointer" }}
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
